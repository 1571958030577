@import '../partials';

.icon-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icon-card {
  border: 1px solid $white;
  color: $white;
  width: calc(33% - 12px);
  min-width: 300px;
  min-height: rem(150);
  text-align: center;
  position: relative;
  margin-top: rem(15);
  margin-left: rem(6);
  margin-right: rem(6);

  &:hover {
    .icon-card__bottom {
      opacity: 1;
    }
  }

  &__boundary {
    height: 100%;
  }

  &__top {
    padding: rem(40);
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__icon {
    width: rem(70);
    margin-right: rem(30);
  }

  &__title {
    font-size: rem(22);
    line-height: rem(28);
    width: 100%;
  }

  &__link {
    color: $white;
    font-size: rem(14);
    line-height: rem(20);
    font-weight: 800;
    margin-top: rem(7);
  }

  &__excerpt {
    font-size: rem(14);
    line-height: rem(20);
  }

  &__bottom {
    opacity: 0;
    padding: rem(40);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background-color: #13212f;
    transition: opacity $fast-duration ease;
  }
}